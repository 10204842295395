import { createStore } from 'vuex';

import auth from './auth';
import users from './users';
import labels from './labels';
import orders from './orders';


export default createStore({
  modules: {
    auth,
    users,
    labels,
    orders,
  }
});