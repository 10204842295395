import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      const userToken = localStorage.getItem('userToken');
      if (userToken) {
        next();
      } else {
        next('/login'); // Redirect to login if user token doesn't exist
      }
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/report',
    name: 'Rapport',
    component: () => import(/* webpackChunkName: "report" */ '../views/ReportView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
