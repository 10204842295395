<template>
  <div>
    <div v-if="isLoggedIn">
      <div class="dashboard pa-8">
        <h2>Velkommen, {{ user }}</h2>
        <h1>{{ dashboard }}</h1>
        <v-container class="pl-0">
          <v-row class="pa-16 pl-0 pt-0">
            <v-col cols="auto">
              <router-link to="/report">
                <v-card class="mx-auto" width="230" :color="color" :variant="variant">
                  <v-card-item>
                    <div>
                      <div class="text-overline mb-1">Dan rapport</div>
                      <div class="text-h6 mb-1">Fra dato interval</div>
                    </div>
                  </v-card-item>
                </v-card>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-else>
      <p v-if="isLoggedIn">Loading dashboard...</p>
      <p v-else>Please log in to access the dashboard</p>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();
  const color = ref('indigo-darken-4')
  const user = localStorage.getItem('username');
  const dashboard = "Dashboard";
  const variant = "elevated";

  onMounted(async () => {
    try {
      if (store.getters['auth/isLoggedIn']) {
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  });

  const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
  
</script>