import axios from 'axios';

const state = {
  orders: [] // Initial state to store orders
};

const getters = {
  // Get all orders
  allOrders: (state) => state.orders,
};

const actions = {
  async fetchOrders({ commit }, { from, to }) {
    console.log('from: ', from);
    console.log('to: ', to);
    const userToken = localStorage.getItem('userToken'); 
    if (!userToken) {
      // Redirect to login page if the token is not found
      router.push({ name: 'login' });
      return;
    }

    try {
      // Construct the API URL dynamically using template literals
      const response = await axios.get(`https://api.datafangst.dk/api/rackbeatrange?from=${from}&to=${to}&limit=999`, {
        headers: { Authorization: `Bearer ${userToken}` }
      });
      commit('setOrders', response.data.orders); // Assuming response.data.orders matches your data structure
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  }
};

const mutations = {
  setOrders: (state, orders) => {
    state.orders = orders;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
